<template>
  <div
    class="ant-panel-wrapper"
    :class="{
      vertical: isBottom,
    }"
    :style="{
      ...styleWrapper,
      ...styleCollapsing,
    }"
  >
    <div
      class="d-flex justify-center ant-panel-resize-stick ant-glass-background radius-0"
      :class="{
        left: isLeft,
        active: isResizing,
        'full-width': isBottom,
      }"
      @mousedown="startResize"
      @touchstart="startResize"
    >
      <v-icon v-if="isBottom"> mdi-drag-horizontal-variant </v-icon>
      <v-icon v-else> mdi-drag-vertical-variant </v-icon>
    </div>
    <div
      class="ant-panel-content ant-panel"
      :class="{
        'ant-border-top': isBottom,
        'ant-border-left': isRight,
        'ant-border-right': isLeft,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
const SIDE_BOTTOM = 'bottom';
const SIDE_LEFT = 'left';
const SIDE_RIGHT = 'right';

export default {
  name: 'PanelResizable',
  props: {
    minWidth: {
      type: Number,
      default: 100,
    },
    maxWidth: {
      type: Number,
      default: null,
    },
    minHeight: {
      type: Number,
      default: 100,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    defaultWidth: {
      type: Number,
      default: 100,
    },
    defaultHeight: {
      type: Number,
      default: 100,
    },
    side: {
      type: String,
      default: SIDE_BOTTOM,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    width: 0,
    height: 0,
    sx: 0,
    sy: 0,
    dx: 0,
    dy: 0,
  }),
  computed: {
    isBottom() {
      return this.side === SIDE_BOTTOM;
    },
    isLeft() {
      return this.side === SIDE_LEFT;
    },
    isRight() {
      return this.side === SIDE_RIGHT;
    },
    currentWidth() {
      const width = this.width + this.dx * (this.isLeft ? -1 : 1);
      if (this.collapsible) {
        return width;
      }
      return this.minWidth > width ? this.minWidth : width;
    },
    currentHeight() {
      const height = this.height + this.dy;
      if (this.collapsible) {
        return height;
      }
      return this.minHeight > height ? this.minHeight : height;
    },
    isCollapsing() {
      if (!this.collapsible) return false;
      switch (this.side) {
        case SIDE_RIGHT:
        case SIDE_LEFT:
          return this.currentWidth < this.minWidth;
        case SIDE_BOTTOM:
          return this.currentHeight < this.minHeight;
        default:
          return false;
      }
    },
    isResizing() {
      return !!this.sx || !!this.sy;
    },
    styleWrapper() {
      switch (this.side) {
        case SIDE_RIGHT:
          return {
            width: `${this.currentWidth}px`,
          };
        case SIDE_BOTTOM:
          return {
            height: `${this.currentHeight}px`,
          };
        case SIDE_LEFT:
          return {
            width: `${this.currentWidth}px`,
            'flex-direction': 'row-reverse',
          };
        default:
          return {};
      }
    },
    styleCollapsing() {
      if (!this.isCollapsing) return {};
      switch (this.side) {
        case SIDE_LEFT:
          return {
            transform: 'translateX(-80%)',
          };
        case SIDE_RIGHT:
          return {
            transform: 'translateX(80%)',
          };
        case SIDE_BOTTOM:
          return {
            transform: 'translateY(80%)',
          };
        default:
          return {};
      }
    },
  },
  mounted() {
    this.width = this.defaultWidth;
    this.height = this.defaultHeight;
  },
  methods: {
    startResize(e) {
      this.sx = e.clientX || e.targetTouches?.item(0)?.clientX;
      this.sy = e.clientY || e.targetTouches?.item(0)?.clientY;
      document.addEventListener('mousemove', this.dragSidebar);
      document.addEventListener('touchmove', this.dragSidebar);
      document.addEventListener('mouseup', this.stopResize);
      document.addEventListener('touchend', this.stopResize);
    },
    dragSidebar(e) {
      this.dx = this.sx - (e.clientX || e.targetTouches?.item(0)?.clientX);
      this.dy = this.sy - (e.clientY || e.targetTouches?.item(0)?.clientY);
      this.$emit('resize', {
        width: this.currentWidth,
        height: this.currentHeight,
      });
    },
    stopResize(e) {
      document.removeEventListener('mousemove', this.dragSidebar);
      document.removeEventListener('touchmove', this.dragSidebar);
      document.removeEventListener('mouseup', this.stopResize);
      document.removeEventListener('touchend', this.stopResize);

      this.width = this.width + this.dx * (this.isLeft ? -1 : 1);
      this.height = this.height + this.dy;
      if (!this.collapsible && this.minWidth > this.width) {
        this.width = this.minWidth;
      }
      if (
        (this.side === SIDE_LEFT || this.side === SIDE_RIGHT) &&
        this.maxWidth
      ) {
        if (this.width > this.maxWidth) {
          this.width = this.maxWidth;
          this.$store.commit('showNotification', {
            content: 'Max width reached',
            color: 'info',
          });
        }
      }
      if (!this.collapsible && this.minHeight > this.height) {
        this.height = this.minHeight;
      }
      if (this.side === SIDE_BOTTOM && this.maxHeight) {
        if (this.height > this.maxHeight) {
          this.height = this.maxHeight;
          this.$store.commit('showNotification', {
            content: 'Max height reached',
            color: 'info',
          });
        }
      }

      this.sx = 0;
      this.sy = 0;
      this.dx = 0;
      this.dy = 0;

      this.$emit('resize-end', {
        width: this.currentWidth,
        height: this.currentHeight,
      });
      if (this.isCollapsing) {
        this.$emit('collapse');
        this.$nextTick(() => {
          this.width = this.defaultWidth;
          this.height = this.defaultHeight;
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-panel-wrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  transition: transform 0.1s ease-in-out;

  .ant-panel-resize-stick {
    cursor: col-resize;
    align-self: stretch;
    z-index: 100;

    &.active {
      box-shadow: 4px 0 5px -3px rgba(0, 0, 0, 0.2);
    }
    &.left.active {
      box-shadow: -4px 0 5px -3px rgba(0, 0, 0, 0.2);
    }
  }

  .ant-panel-content {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &.vertical {
    flex-direction: column;

    .ant-panel-resize-stick {
      cursor: row-resize;

      &.active {
        box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.2);
      }
    }

    .ant-panel-content {
    }
  }
}
</style>
