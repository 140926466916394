import moment from 'moment';

export function getChartHeaderIcon(state) {
  switch (state) {
    case 'month':
      return 'mdi-calendar-month';
    case 'week':
      return 'mdi-calendar-week';
    case 'day':
      return 'mdi-calendar-today';
    default:
      return 'mdi-calendar-today';
  }
}

export function getChartHeaderFormat(state) {
  switch (state) {
    case 'month':
      return "MMM'YY";
    case 'week':
      return 'ww';
    case 'day':
      return "D MMM'YY";
    default:
      return 'YYYY-MM-DD';
  }
}

export function getChartHeaderWidth(state, width, date, index) {
  switch (state) {
    case 'month':
      return width * date.clone().add(index, state).daysInMonth();
    case 'week':
      return width * 7;
    case 'day':
      return width;
    default:
      return 'YYYY-MM-DD';
  }
}
export function getChartHeaderDayWidth(state) {
  switch (state) {
    case 'month':
      return 7;
    case 'week':
      return 30;
    case 'day':
      return 100;
    default:
      return 30;
  }
}

export function getChartStartDate(tasks, state) {
  if (tasks.length > 0) {
    let sortedByStart = [...tasks.filter((task) => task.planned_start)].sort(
      (a, b) => moment(a.planned_start) - moment(b.planned_start)
    );
    let latest;
    if (sortedByStart.length === 0) {
      let sortedByDue = [...tasks.filter((tasks) => tasks.due)].sort(
        (a, b) => moment(a.due) - moment(b.due)
      );
      if (sortedByDue.length > 0) {
        latest = sortedByDue[0];
        return moment(latest.due).clone().subtract(1, state).startOf(state);
      }
      return moment().subtract(1, state).startOf(state);
    } else {
      latest = sortedByStart[0];
      return moment(latest.planned_start)
        .clone()
        .subtract(1, state)
        .startOf(state);
    }
  }
  return moment().subtract(1, state).startOf(state);
}

export function getChartEndDate(tasks, state) {
  if (tasks.filter((t) => t.due).length > 0) {
    let sortedByEnd = [...tasks].sort((a, b) => moment(a.due) - moment(b.due));
    let latest = sortedByEnd[sortedByEnd.length - 1];

    return moment(latest.due).clone().add(1, state).endOf(state);
  }
  return moment().add(1, state).endOf(state);
}

export function getChartTaskOffset(task, dayWidth, chartStart) {
  let hours = moment(task?.planned_start).diff(chartStart, 'hours');
  return hours * (dayWidth / 24);
}
export function getChartTaskWidth(task, dayWidth) {
  if (task?.planned_start && task?.planned_end) {
    let hours = moment(task.planned_end).diff(
      moment(task.planned_start),
      'hours'
    );
    return hours * (dayWidth / 24);
  } else {
    return 0;
  }
}

export function getChartTaskColor(task) {
  if (task?.labels?.length === 0 || !task.labels) {
    return '#616161';
  } else {
    if (task?.labels.length === 1) {
      return task.labels[0].color;
    } else {
      return `linear-gradient(45deg, ${task.labels
        .map((label) => label.color)
        .join(',')})`;
    }
  }
}

export function stringToConstantColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let j = 0; j < 3; j++) {
    const value = (hash >> (j * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
