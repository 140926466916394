<template>
  <div
    class="background-white module-navigation-bar ant-border-bottom radius-0"
  >
    <!--    <v-tooltip right>-->
    <!--      <template #activator="{ on, attrs }">-->
    <!--        <v-icon-->
    <!--          class="ant-icon mr-2"-->
    <!--          v-bind="attrs"-->
    <!--          v-on="on"-->
    <!--          @click="openKnowledgeBase"-->
    <!--        >-->
    <!--          mdi-information-outline-->
    <!--        </v-icon>-->
    <!--      </template>-->
    <!--      <span>Open module knowledge base</span>-->
    <!--    </v-tooltip>-->
    <div class="module-nav-header">
      {{ title }}
      <slot name="module-nav-header" />
    </div>
    <div class="viewer-actions">
      <slot name="module-nav-actions" />
    </div>
    <div class="company-logo">
      <slot name="module-nav-logo" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ModuleNavigationBar',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['project']),
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === this.$route.name
      ).id;
    },
  },
  methods: {
    openKnowledgeBase() {
      console.log(this.moduleId);
      this.$router.push({
        name: 'KnowledgeBase',
        query: { module: this.moduleId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.module-navigation-bar {
  padding: 0 20px;
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 50;
  flex-shrink: 0;

  .module-nav-header {
    color: var(--v-primary-base);
    display: flex;
    align-items: center;
    font-size: 22px;
  }

  .viewer-actions {
    margin-left: 40px;
    display: flex;
    align-items: center;
    flex: 1;

    .viewer-action {
      margin: 0 10px;
      padding: 5px 10px;
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      // width: 180px;
      border-bottom: solid 2px transparent;

      &:hover {
        background-color: white;
        box-sizing: border-box;
        border-bottom: solid 2px var(--v-primary-base);
      }

      .v-icon {
        margin-right: 5px;
      }

      &.viewer-action-active {
        background-color: white;
        box-sizing: border-box;
        border-bottom: solid 2px var(--v-primary-base);

        &:hover {
          background-color: transparent;
        }

        .v-icon {
          color: var(--v-primary-base);
        }
      }
    }
  }
}
</style>
