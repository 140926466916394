export default {
  data: () => {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
        url: (value) => {
          const isUrl =
            /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
          return !value || isUrl.test(value) || 'Invalid url.';
        },
      },
    };
  },
};
