var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ant-panel-wrapper",class:{
    vertical: _vm.isBottom,
  },style:({
    ..._vm.styleWrapper,
    ..._vm.styleCollapsing,
  })},[_c('div',{staticClass:"d-flex justify-center ant-panel-resize-stick ant-glass-background radius-0",class:{
      left: _vm.isLeft,
      active: _vm.isResizing,
      'full-width': _vm.isBottom,
    },on:{"mousedown":_vm.startResize,"touchstart":_vm.startResize}},[(_vm.isBottom)?_c('v-icon',[_vm._v(" mdi-drag-horizontal-variant ")]):_c('v-icon',[_vm._v(" mdi-drag-vertical-variant ")])],1),_c('div',{staticClass:"ant-panel-content ant-panel",class:{
      'ant-border-top': _vm.isBottom,
      'ant-border-left': _vm.isRight,
      'ant-border-right': _vm.isLeft,
    }},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }